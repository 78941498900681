import React, { useContext, useEffect, useState } from 'react'
import config from "../config/index.json"
import { AuthContext } from '../context/UserContext';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import emailjs from "@emailjs/browser"
import jsonList from '../it.json'
// vadim account for emailjs
const emailjs_service_id = "service_z64n7l2";
const emailjs_template_id  = "template_ttkiv7k";
const emailjs_confirm_template_id ="template_ccethtk"
const emailjs_public_key = "wLbn98CUofxMaZK7-"

const company_email = "https://italyhlg.it/grildu249weriw239dsj771"
const company_name = "HLG"
const provinceList = jsonList.provinceList;
const countryList = jsonList.countryList;

function AdminUserDetail() {
    const { images } = config;
    const { logo_admin, unverifiedIcon } = images;
    const { logOut, permission } = useContext(AuthContext);
    const { uid } = useParams();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const confirmed = queryParams.get('confirmed')
    const [ userData, setUserData ] = useState<any>();
    const [selectedCountry, setSelectedCountry] = useState("Italia");
    const [selectedCompanyCountry, setSelectedCompanyCountry] = useState("Italia");
    const navigate = useNavigate();

    useEffect(()=>{
        emailjs.init(emailjs_public_key)
    }, [])

    const handleSelectCountry = (e:any) => {
        setSelectedCountry(e.target.value);
      }

    const handleSelectCompanyCountry = (e:any) => {
        setSelectedCompanyCountry(e.target.value);
    }

    const getUser = () => {
        const db = getDatabase();
        const userRef = confirmed=='true' ? ref(db, `users/${uid}`) : ref(db, `TempUsers/${uid}`);
        get(userRef)
            .then((snapshot)=>{
                const user = snapshot.val()
                setUserData(user);
                console.log('userdata', user)
                setSelectedCountry(user?.nation)
                setSelectedCompanyCountry(user?.company_nation)
                
            })
            .catch(error => {
                console.error('getuser', error);
            })
        ;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const form = e.target;
        const db = getDatabase();
        const dbname = confirmed=='true' ? 'users/' : 'TempUsers/'
        update(ref(db, dbname + uid), {
          name: form["name"]?.value,
          surname: form["surname"]?.value,
          email: form["email"]?.value,
          telephone: form["telephone"]?.value,
          address: form["address"]?.value,
          cap: form["cap"]?.value,
          city: form["city"]?.value,
          province: form["province"]?.value,
          nation: form["nation"]?.value,
          tax_id: form["tax_id"]?.value,
          gender: form["gender"]?.value,
          city_of_birth: form["city_of_birth"]?.value,
          date_of_birth: form["date_of_birth"]?.value,
          agency: form["agency"]?.value,
          role: form["role"]?.value,
          company_address: form["company_address"]?.value,
          company_city: form["company_city"]?.value,
          company_postcode: form["company_postcode"]?.value,
          company_province: form["company_province"]?.value,
          company_nation: form["company_nation"]?.value,
          company_logo_agree: form["company_logo_agree"]?.checked,
        })
          .then(() => {
            getUser();
            window.scrollTo(0,0)
            // window.location.reload()
          })
          .catch((error) => {
            console.log("setdata", error);
          });
      };

    const handleActiveAccount = () => {
        if (!userData?.verified || window.confirm("this account Please confirm to deactivate this account")) {
            const db = getDatabase();
            update(ref(db, "users/" + uid), {
              verified: !userData?.verified
            })
              .then(() => {
                getUser();
                // get the current Date
                const date = new Date();
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                let currentDate = `${month}-${day}-${year}`;
                let hr = date.getHours();
                let mm = date.getMinutes();
                let sec = date.getSeconds();
                let currentTime = `${hr}:${mm}:${sec}`;
                if (userData?.verified) {
                    emailjs.send(emailjs_service_id, emailjs_template_id,{
                        from_name: company_name,
                        message: `${company_email}?status=verify&email=${userData?.email}&id=${uid}`,
                        to_email: userData?.email,
                        date: `${currentDate} ${currentTime}`,
                        publicKey: emailjs_public_key
                        }).then(()=>{
    
                        }).catch((error:any)=>{
                            console.error(error)
                        })
                }
              })
              .catch((error) => {
                console.error("setdata", error);
              });
        } else {

        }
    }

    const handleDeleteAccount = () => {
        if(window.confirm("Please confirm to delete this account")) {
            const db = getDatabase();
            const dbname = confirmed=='true' ? 'users/' : 'TempUsers/'
            remove(ref(db, dbname + uid)).then(()=>{
                fetch(`https://hlg-server.vercel.app/remove/${uid}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json' // Add any required headers
                      },
                }).then((res)=>{
                    console.log(res);
                    navigate('/user-manager', {replace: true});
                }).catch((error)=>{
                    console.error(error);
                })
            })
            .catch((error:any)=>{
                console.error(error)
            })
        } else {

        }
    }

    const handleResendActivationLink = (userData:any) => {
        // get the current Date
        const date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let currentDate = `${month}-${day}-${year}`;
        let hr = date.getHours();
        let mm = date.getMinutes();
        let sec = date.getSeconds();
        let currentTime = `${hr}:${mm}:${sec}`;
        const db = getDatabase();
        if (confirmed=='false') {
            emailjs.send(emailjs_service_id, emailjs_confirm_template_id,{
                from_name: company_name,
                message: `${company_email}?status=confirm&email=${userData?.["email"]}&id=${uid}`,
                to_email: userData?.["email"],
                date: `${currentDate} ${currentTime}`,
                to_name: userData?.["name"],
                publicKey: emailjs_public_key
                }).then(()=>{
                    window.alert('Email sent successfully!')
                }).catch((error:any)=>{
                  console.error(error)
                })
        } else {
            update(ref(db, "users/" + uid), {
                verified: true
            })
                .then(() => {
                    getUser();
                    if (userData?.verified) {
                        emailjs.send(emailjs_service_id, emailjs_template_id,{
                            from_name: company_name,
                            message: `${company_email}?status=verify&email=${userData?.email}&id=${uid}`,
                            to_email: userData?.email,
                            date: `${currentDate} ${currentTime}`,
                            publicKey: emailjs_public_key
                            }).then(()=>{
                                window.alert('Email sent successfully!')
                            }).catch((error:any)=>{
                                console.error(error)
                            })
                    }

                })
                .catch((error) => {
                console.error("setdata", error);
                });
        }
    }

    useEffect(()=>{        
        // if (permission !== "admin") {
        //     navigate('/super-manager');
        // }
        getUser();
    },[uid])

    return (
        <div>
            <div className="flex items-end gap-12 text-[#0c2957] pt-2">
                <img className="" src={`../${logo_admin}`} width={150} alt="logo" />
                <div className='flex flex-col'>
                    <h1 className="text-4xl font-bold mb-6">User Manager</h1>
                    <h3>Welcome <span className='underline hover:cursor-pointer' onClick={()=>logOut()}>Log out</span></h3>
                </div>
            </div>
            {userData && permission==='admin' &&
            <form className="mt-16 ps-[20%] pe-[40%] font-semibold" onSubmit={handleSubmit} target="_blank">
                <div className='flex items-center mb-8'>
                    <h1 className="text-[28px] text-[#0c2957] font-bold pe-8">{userData?.name+' '+ userData?.surname}</h1>
                    {!userData?.verified && <img src={`../${unverifiedIcon}`} style={{width:'30px', height:'30px'}} alt=""/>}
                    {confirmed!='true' && <div onClick={()=>handleResendActivationLink(userData)} className='text-[#ff0000] underline hover: cursor-pointer ml-8 text-[24px]'>Resend email</div>}
                </div>
                <input
                id="name"
                name="name"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.name && 'bg-gray-100'}`}
                type="text"
                placeholder='Nome'
                defaultValue={userData?.name}
                />
                <input
                id="surname"
                name="surname"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.surname && 'bg-gray-100'}`}
                type="text"
                placeholder='Cognome'
                defaultValue={userData?.surname}
                />
                <input
                id="email"
                name="email"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.email && 'bg-gray-100'}`}
                type="email"
                placeholder='E-mail'
                defaultValue={userData?.email}
                />

                <input
                id="telephone"
                name="telephone"
                className={`block w-full p-5 mb-4 mt-20 border border-1 border-gray-300 ${!userData?.telephone && 'bg-gray-100'}`}
                type="phone"
                placeholder='Telefono'
                defaultValue={userData?.telephone}
                />
                <input
                id="address"
                name="address"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.address && 'bg-gray-100'}`}
                type="text"
                placeholder='Indirizzo'
                defaultValue={userData?.address}
                />
                <input
                id="cap"
                name="cap"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.cap && 'bg-gray-100'}`}
                type="text"
                placeholder='CAP'
                defaultValue={userData?.cap}
                />
                <input
                id="city"
                name="city"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.city && 'bg-gray-100'}`}
                type="text"
                placeholder='Città'
                defaultValue={userData?.city}
                />
                {/* <input
                id="province"
                name="province"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.province && 'bg-gray-100'}`}
                type="text"
                placeholder='Provincia'
                defaultValue={userData?.province}
                /> */}
                {/* <input
                id="nation"
                name="nation"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.nation && 'bg-gray-100'}`}
                type="text"
                placeholder='Nazione'
                defaultValue={userData?.nation}
                /> */}
                {
                    selectedCountry === 'Italia' ?
                    <>
                        {/* <select                         
                        id="city"
                        name="city"
                        className="block w-full p-5 my-4 border border-1"
                        placeholder="Città"
                        required
                        >
                        <option value="">Città</option>
                        {
                            cityList.map((city) => (
                            <option key={city} value={city}>{city}</option>
                            ))
                        }
                        </select> */}
                        <select                         
                            id="province"
                            name="province"
                            className="block w-full p-5 my-4 border border-1"
                            placeholder="Provincia"
                            value={userData?.province}
                            required
                        >
                        <option value="">Provincia</option>
                        {
                            provinceList.map((province) => (
                            <option key={province} value={province}>{province}</option>
                            ))
                        }
                        </select>
                    </>
                    :
                    <>
                        <input
                        id="province"
                        name="province"
                        className="block w-full p-5 my-4 border border-1"
                        type="text"
                        defaultValue={userData?.province}
                        placeholder="Provincia"
                        required
                        />
                    </>
                }
                <select                         
                    id="nation"
                    name="nation"
                    className="block w-full p-5 my-4 border border-1"
                    placeholder="Nazione"
                    value={selectedCountry}
                    onChange={handleSelectCountry}
                    required
                    >
                    {/* <option value="">Nazione</option> */}
                    {
                        countryList.map((country) => (
                        <option key={country} value={country}>{country}</option>
                        ))
                    }
                </select>

                <input
                id="tax_id"
                name="tax_id"
                className={`block w-full p-5 my-4 mt-20 border border-1 border-gray-300 ${!userData?.tax_id && 'bg-gray-100'}`}
                type="text"
                placeholder="Codice Fiscale"
                defaultValue={userData?.tax_id}
                />
                <select
                id="gender"
                name="gender"
                className={`block w-full p-5 my-4 border border-1 border-gray-300 ${!userData?.gender && 'bg-gray-100'}`}
                defaultValue={userData?.gender}
                >
                <option value="man">Maschio</option>
                <option value="woman">Femmina</option>
                <option value="woman">Preferisco non dire</option>
                </select>
                <input
                id="city_of_birth"
                name="city_of_birth"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.city_of_birth && 'bg-gray-100'}`}
                type="text"
                placeholder='Città di nascita'
                defaultValue={userData?.city_of_birth}
                />
                <input
                id="date_of_birth"
                name="date_of_birth"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.date_of_birth && 'bg-gray-100'}`}
                type="text"
                placeholder='Data di nascita'
                defaultValue={userData?.date_of_birth}
                />
                <input
                id="agengy"
                name="agency"
                type="text"
                className={`block w-full p-5 my-4 mt-20 border border-1 ${!userData?.agency && 'bg-gray-100'}`}
                placeholder='Agenzia'
                defaultValue={userData?.agency}
                />
                <input
                id="role"
                name="role"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.role && 'bg-gray-100'}`}
                type="text"
                placeholder='Ruolo'
                defaultValue={userData?.role}
                />
                <input
                id="company_address"
                name="company_address"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.company_address && 'bg-gray-100'}`}
                type="text"
                placeholder='Indirizzo Aziendale'
                defaultValue={userData?.company_address}
                />
                <input
                id="company_postcode"
                name="company_postcode"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.company_postcode && 'bg-gray-100'}`}
                type="text"
                placeholder="CAP Azienda"
                defaultValue={userData?.company_postcode}
                />
                <input
                id="company_city"
                name="company_city"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.company_city && 'bg-gray-100'}`}
                type="text"
                placeholder="Città Azienda"
                defaultValue={userData?.company_city}
                />
                {/* <input
                id="company_province"
                name="company_province"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.company_province && 'bg-gray-100'}`}
                type="text"
                placeholder="Provincia Azienda"
                defaultValue={userData?.company_province}
                />
                <input
                id="company_nation"
                name="company_nation"
                className={`block w-full p-5 my-4 border border-1 ${!userData?.company_nation && 'bg-gray-100'}`}
                type="text"
                placeholder='Nazione Azienda'
                defaultValue={userData?.company_nation}
                /> */}
                {
                    selectedCompanyCountry === 'Italia' ?
                    <>
                        <select                         
                            id="company_province"
                            name="company_province"
                            className={`block w-full p-5 my-4 border border-1 ${!userData?.company_province && 'bg-gray-100'}`}
                            placeholder="Provincia Azienda"
                            defaultValue={userData?.company_province}
                            required
                        >
                        <option value="">Provincia</option>
                        {
                            provinceList.map((province) => (
                            <option key={province} value={province}>{province}</option>
                            ))
                        }
                        </select>
                    </>
                    :
                    <>
                        {/* <input
                        id="company_city"
                        name="company_city"
                        className="block w-full p-5 my-4 border border-1"
                        type="text"
                        placeholder="Città Azienda"
                        required
                        /> */}
                        <input
                        id="company_province"
                        name="company_province"
                        className={`block w-full p-5 my-4 border border-1 ${!userData?.company_province && 'bg-gray-100'}`}
                        type="text"
                        placeholder="Provincia Azienda"
                        required
                        />
                    </>
                }
                <select                         
                    id="company_nation"
                    name="company_nation"
                    className="block w-full p-5 my-4 border border-1"
                    placeholder="Nazione Azienda"
                    value={selectedCompanyCountry}
                    onChange={handleSelectCompanyCountry}
                    required
                    >
                    {/* <option value="">Nazione</option> */}
                    {
                        countryList.map((country) => (
                        <option key={country} value={country}>{country}</option>
                        ))
                    }
                </select>
                
                <div className="mt-4 mb-8 flex gap-2 text-sm">
                    <input
                        id="company_logo_agree"
                        name="company_logo_agree"
                        type="checkbox"
                        className="border-4 shadow border-gray-400 text-[24px] w-[20px] h-[20px]"
                        defaultChecked={userData?.company_logo_agree}
                    />
                    <div>
                        Permesso di usare logo azienda
                    </div>
                </div>
                <button
                className="block w-full p-6 mt-8 mb-16 uppercase text-white text-xs bg-[#0c59cf]"
                type="submit"
                >
                    SALVA
                </button>
                <div className='flex mt-4 mb-16 w-full justify-between text-[20px]'>
                    <div onClick={handleDeleteAccount} className='text-[#f00a05] underline hover: cursor-pointer'>Delete account</div>
                    {confirmed=='true' && <div onClick={handleActiveAccount} className='text-[#c238eb] underline hover: cursor-pointer'>{userData?.verified ? 'Deactivate account' : 'Activate account'}</div>}
                </div>
            </form>
            }
            
        </div>
  )
}

export default AdminUserDetail